<template>
  <div class="list-user">
    <div class="list-user-table">
      <div class="mb-2 row">
        <h2
          class="col-sm-6 col-md-6 col-lg-6 text-truncate-mess font-title font-weight-bold"
        >
          タイミングポップアップ管理
        </h2>
        <div
          class="col-sm-6 col-md-6 col-lg-6 tex-left text-md-right text-sm-right"
        >
          <button class="button-create-user" v-on:click="createTimingPopup()">
            新規登録
          </button>
          <button v-on:click="gotoDashboard()" class="button-back-dashboard">
            戻る
          </button>
        </div>
      </div>
      <div class="row p-3">
        <div class="row-input p-3 col-sm-12">
          <label class="w-100 font-weight-bold pl-0"
            >ポップアップ名（管理用）</label
          >
          <input class="form-control w-100" v-model="popup_name" />
        </div>
      </div>
      <div class="row p-3">
        <label class="col-sm-12 font-weight-bold pl-3">作成日時</label>
        <div
          class="row-input p-3 col-sm-12 col-lg-4 col-md-4"
          style="padding-top: 0px !important"
        >
          <date-picker
            class="w-100"
            name="date"
            v-model="month_from"
            :config="optionsDate"
            autocomplete="off"
            style="margin-bottom: 1rem"
          ></date-picker>
        </div>
        <span
          class="col-auto custom-span text-center p-0 justify-content-center"
          style="line-height: 25px"
          >~</span
        >
        <div
          class="row-input p-3 col-sm-12 col-lg-4 col-md-4"
          style="padding-top: 0px !important"
        >
          <div class="w-100 fd-admin-listproperty-card-filter filter-date">
            <date-picker
              class="w-100"
              name="date"
              v-model="month_to"
              :config="optionsDate"
              autocomplete="off"
              style="margin-bottom: 1rem"
            ></date-picker>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center mt-4 mb-4">
        <b-button
          v-on:click.prevent="getlistTimingPopup()"
          variant="info"
          class="mr-4"
          >絞り込み検索</b-button
        >
        <b-button
          variant="secondary"
          class="mr-4"
          v-on:click="resetFieldSearch()"
          >リセット</b-button
        >
      </div>
      <Tables
        :items="listTimingPopup.data"
        :fields="fields"
        :itemsPerPageSelect="{}"
      >
        <template v-slot:id="{ index }">
          <td>
            {{ listTimingPopup.current_page * limit - limit + index + 1 }}
          </td>
        </template>
        <template v-slot:action="{ item, index }">
          <td class="py-2 pl-2 pr-1 d-flex justify-content-center">
            <router-link
              style="color: black"
              :to="{
                name: $route.params.shopId
                  ? 'Edit Timing Popup'
                  : 'Edit Timing Popup domain',
                params: {
                  shopId: shop_id,
                  id: item.id,
                },
                query: {
                  no: listTimingPopup.current_page * limit - limit + index + 1,
                },
              }"
            >
              <CButton
                class="mx-1 text-nowrap"
                color="primary"
                square
                variant="outline"
                size="sm"
                v-b-modal.modal-comfirm
              >
                編集
              </CButton>
            </router-link>
            <CButton
              class="mx-1 text-nowrap"
              color="danger"
              square
              v-on:click="showConfirm(item.id)"
              variant="outline"
              size="sm"
              v-b-modal.delete-modal-page
            >
              削除
            </CButton>
          </td>
        </template>
        <template v-slot:created_at="{ item }">
          <td>
            {{
              new Date(item.created_at).toLocaleString("ja-JP", {
                timeZone: "Asia/Tokyo",
              })
            }}
          </td>
        </template>
      </Tables>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listTimingPopup.total"
      >
        <p class="font-weight-bold">
          {{ listTimingPopup.total }}件中{{
            listTimingPopup.data.length
          }}件を表示
        </p>
      </div>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listTimingPopup.last_page"
      >
        <CPagination
          v-if="listTimingPopup.total"
          :activePage.sync="page"
          :pages="listTimingPopup.last_page"
          align="center"
        />
        <!-- <div class="custom-selectLimitPage">
          <b-form-select
            v-model="limit"
            tag-placeholder="Add this as new category"
            :options="listLimitRecordPage"
            @change="changeLimitPage()"
          ></b-form-select>
        </div> -->
      </div>
    </div>
    <div>
      <b-modal id="delete-modal-page" hide-header hide-footer>
        <div class="d-block text-center">
          <h5>削除しますと復元できませんのでご注意ください</h5>
        </div>
        <div class="d-flex justify-content-center">
          <b-button
            class="mx-2 mt-3 btn btn-danger"
            block
            @click="removeTimingPopUp(dataModal)"
            >削除</b-button
          >
          <b-button
            class="mx-2 mt-3 btn btn-secondary"
            block
            @click="$bvModal.hide('delete-modal-page')"
          >
            キャンセル
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Tables from "../../components/common/Tables";
import { tableFields } from "../../utils/table-fields.js";
import { mapActions, mapGetters } from "vuex";
import { Constants } from "../../utils/constants";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import $ from "jquery";
import "@fortawesome/fontawesome-free/css/fontawesome.css";
import "@fortawesome/fontawesome-free/css/regular.css";
import "@fortawesome/fontawesome-free/css/solid.css";
$.extend(true, $.fn.datetimepicker.defaults, {
  icons: {
    time: "far fa-clock",
    date: "far fa-calendar",
    up: "fas fa-arrow-up",
    down: "fas fa-arrow-down",
    previous: "fas fa-chevron-left",
    next: "fas fa-chevron-right",
    today: "fas fa-calendar-check",
    clear: "far fa-trash-alt",
    close: "far fa-times-circle",
  },
});
// import $ from "jquery";
export default {
  name: "listTimingPopups",
  data() {
    return {
      fields: tableFields.TIMING_POPUP,
      listLimitRecordPage: [
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 200, text: "200" },
      ],
      popup_name: "",
      limit: 50,
      page: 1,
      messageError: {},
      messageResponse: "",
      messagerErr: "",
      messageSuccess: "",
      lang: "ja",
      month_to: "",
      month_from: "",
      optionsDate: {
        format: "YYYY-MM-DD HH:mm",
        useCurrent: false,
        showClear: false,
        showClose: false,
        locale: "ja",
      },
      shop_id: localStorage.getItem(Constants.SHOP_ID),
    };
  },
  components: {
    Tables,
  },
  async created() {
    await this.getlistTimingPopup(1);
    this.lengthList = this.listTimingPopup.total;
  },
  computed: {
    ...mapGetters(["listTimingPopup", "message", "success", "error"]),
    lengthList() {
      if (this.listTimingPopup) {
        return this.listTimingPopup.total;
      }
      return null;
    },
  },
  watch: {
    success() {
      if (this.success) {
        // this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    listTimingPopup() {
      this.json_data = this.listTimingPopup.data;
      this.page = this.listTimingPopup.current_page;
    },
    page() {
      this.getlistTimingPopup(this.page);
    },
    dateSaleManagementRank() {
      if (this.dateSaleManagementRank.data[0] !== null) {
        const dateStartDefault = new Date(
          this.dateSaleManagementRank.data[0].start_day
        );
        this.yearfrom = dateStartDefault.getFullYear();
        this.monthfrom = dateStartDefault.getMonth() + 1;
        this.dayfrom = dateStartDefault.getDate();
        const dateEndDefault = new Date(
          this.dateSaleManagementRank.data[0].end_day
        );
        this.yearto = dateEndDefault.getFullYear();
        this.monthto = dateEndDefault.getMonth() + 1;
        this.dayto = dateEndDefault.getDate();
      }
    },
  },
  methods: {
    ...mapActions({
      getListTimingPopUp: "getListTimingPopUp",
    }),
    changeLang() {
      this.lang === "ja";
    },
    async getlistTimingPopup(page) {
      if (page === undefined) {
        page = this.page;
      }
      const formData = {
        page: page,
        data: {
          shop_id: this.shop_id,
          limit: this.limit,
          created_from: this.month_from,
          created_to: this.month_to,
          popup_title_admin: this.popup_name,
        },
      };
      await this.getListTimingPopUp(formData);
    },
    changeLimitPage() {
      this.getlistTimingPopup(1);
    },
    gotoDashboard() {
      if (this.$route.params.shopId) {
        this.$router.push({ name: "Dashboard Admin" });
      } else {
        this.$router.push({ name: "Dashboard Admin domain" });
      }
    },
    resetFieldSearch() {
      this.month_from = "";
      this.month_to = "";
      this.popup_name = "";
      const formData = {
        page: 1,
        data: {
          shop_id: this.shop_id,
          limit: this.limit,
          created_from: this.month_from,
          created_to: this.month_to,
          popup_title_admin: this.popup_name,
        },
      };
      this.getListTimingPopUp(formData);
    },
    showConfirm(id) {
      this.dataModal = id;
    },
    async removeTimingPopUp(id) {
      const data = {
        id: id,
        shop_id: this.shop_id,
      };
      const dataReturn = await this.$store.dispatch("deleteTimingPopUp", data);
      if (dataReturn.success) {
        this.$toasted.success(dataReturn.message);
        this.$nextTick(() => {
          this.$bvModal.hide("delete-modal-page");
          this.getlistTimingPopup(this.page);
        });
      } else {
        this.$toasted.error(dataReturn.message);
      }
    },
    createTimingPopup() {
      this.$router.push({
        name: this.$route.params.shopId
          ? "Create Timing Popup"
          : "Create Timing Popup domain",
        query: { no: this.lengthList + 1 },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-span {
  @media (max-width: 420px) {
    display: none;
  }
  @media (max-width: 720px) {
    display: none;
  }
}
</style>
